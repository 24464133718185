import { useCallback, useEffect, useMemo } from "react";

import { flow } from "lodash/fp";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers } from "recompose";
import { Field, propTypes, reduxForm, reset } from "redux-form";

import {
  Button,
  CoverModalButton,
  FormControl,
  Modal,
} from "@dpdgroupuk/mydpd-ui";

import DoubleAstericksInput from "~/components/DoubleAstericksInput/DoubleAstericksInput";
import {
  EDIT_IMPORTER_FORM,
  IMPORTER_DETAILS,
  ImporterDetailsEntity,
} from "~/constants/forms";
import { HELP_INFO, IMPORTER_UKIMS_INFO } from "~/constants/info";
import * as S from "~/constants/strings";
import PostcodeFinderInput from "~/features/PostcodeFinder/PostcodeFinderInput";
import withInvoicePostcodeAutocomplete from "~/hocs/withInvoicePostcodeAutocomplete";
import {
  AddressModels,
  Normalizers,
  ServiceModels,
  SettingsModels,
} from "~/models";
import postcodeValidation from "~/models/validators/additionalPostcodeValidation";
import {
  importerEoriValidation,
  importerUkimsValidation,
  invoiceImporterCountryValidation,
} from "~/models/validators/additionalValidations";
import { importerDetailsSchema } from "~/models/validators/importerDetailsSchema";
import { ShipmentActions } from "~/pages/Shipment/redux";
import createValidator from "~/utils/joiReduxForm";
import { touchFields } from "~/utils/reduxForm";
import { joinStringsWithComma } from "~/utils/string";
import { getValue } from "~/utils/object";

import CountriesAutocomplete from "../Autocomplete/CountriesAutocomplete";
import DeliveryContactCard from "../ReviewCard/cards/DeliveryContact";
import styles from "./EditImporterModal.module.scss";
import {
  getEditImporterFormValues,
  getRequiredFields,
  getImporterSelectedCountry,
  getAllowedFields,
} from "./selectors";
import OldImporterBusinessFields from "~/components/ImporterBusinessFields/OldVersion";
import NewImporterBusinessFields from "~/components/ImporterBusinessFields/NewVersion";
import { UmsSelectors } from "~/redux";

const EditImporterModal = ({
  onFieldEntry,
  onPostcodeSelectionChange,
  open,
  onHide,
  onReset,
  countries,
  handleSubmit,
  requiredFields,
  onImporterTypeChange,
  selectedService,
  onCountryChange,
  editImporterSelectedCountry,
  onLoad,
  disabled,
  shippingVersion,
  formValues,
  allowedFields,
}) => {
  useEffect(() => {
    onLoad();
  }, []);

  const onCancel = useCallback(() => {
    onHide();
    onReset();
  }, [onReset, onHide]);
  const withAutocomplete = useMemo(
    () => AddressModels.isCountryGB(editImporterSelectedCountry?.countryKey),
    [editImporterSelectedCountry?.countryKey]
  );
  const hideOptions = useMemo(
    () =>
      ServiceModels.shouldHideBusinessConsumerOptions(
        selectedService,
        shippingVersion
      ),
    [selectedService]
  );
  const isNewVersion = useMemo(
    () => SettingsModels.isNewVersion(shippingVersion),
    [shippingVersion]
  );

  const helpInfo = useMemo(
    () =>
      isNewVersion
        ? [...HELP_INFO[IMPORTER_DETAILS], IMPORTER_UKIMS_INFO]
        : HELP_INFO[IMPORTER_DETAILS],
    [shippingVersion]
  );

  return (
    <Modal
      id="edit-importer-modal"
      className={styles.editImporterDialog}
      show={open}
      onHide={onCancel}
    >
      <Modal.Header>
        {S.EDIT_IMPORTER}
        <CoverModalButton
          items={helpInfo}
          title={S.EDIT_IMPORTER}
          helpModalContainer={styles.helpModalContainer}
          confirmButtonText={S.GO_BACK}
          confirmButtonVariant="dark"
        />
      </Modal.Header>
      <Modal.Body>
        <Container className="p-0">
          <Row>
            <Col>
              <Row>
                <Col>
                  <Field
                    component={CountriesAutocomplete}
                    id={ImporterDetailsEntity.ADDRESS.COUNTRY_CODE}
                    name={ImporterDetailsEntity.ADDRESS.COUNTRY_CODE}
                    label={S.IMPORTER_COUNTRY}
                    helperText
                    onCountryChange={onCountryChange}
                    countries={countries}
                    required={
                      requiredFields[ImporterDetailsEntity.ADDRESS.COUNTRY_CODE]
                    }
                    selectedCountry={editImporterSelectedCountry}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={PostcodeFinderInput}
                    label={S.POSTAL_ZIP_CODE}
                    labelKey={option => option.postcode}
                    optionLabelMapper={option =>
                      joinStringsWithComma([
                        option.organisation,
                        option.property,
                        option.street,
                        option.town,
                      ])
                    }
                    onSelectionChange={values => {
                      onPostcodeSelectionChange(values);
                    }}
                    showFindButton={false}
                    name={ImporterDetailsEntity.ADDRESS.POSTCODE}
                    maxLength={8}
                    pageSize={200}
                    maxItems={200}
                    withAutocomplete={withAutocomplete}
                    helperText={S.DESTINATION}
                    onBlur={onFieldEntry}
                    required={
                      requiredFields[ImporterDetailsEntity.ADDRESS.POSTCODE]
                    }
                    id={ImporterDetailsEntity.ADDRESS.POSTCODE}
                    selectedCountry={editImporterSelectedCountry}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={S.ORGANISATION_OR_NAME}
                    name={ImporterDetailsEntity.ADDRESS.ORGANISATION}
                    maxLength={35}
                    helperText={S.MAX_35_CHARACTERS}
                    onBlur={onFieldEntry}
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={S.ADDRESS_LINE_1}
                    name={ImporterDetailsEntity.ADDRESS.STREET}
                    maxLength={35}
                    helperText={S.MAX_35_CHARACTERS}
                    onBlur={onFieldEntry}
                    required={
                      requiredFields[ImporterDetailsEntity.ADDRESS.STREET]
                    }
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={S.ADDRESS_LINE_2}
                    name={ImporterDetailsEntity.ADDRESS.LOCALITY}
                    maxLength={35}
                    helperText={S.MAX_35_CHARACTERS}
                    onBlur={onFieldEntry}
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={S.CITY}
                    name={ImporterDetailsEntity.ADDRESS.TOWN}
                    maxLength={35}
                    helperText={S.MAX_35_CHARACTERS}
                    onBlur={onFieldEntry}
                    required={
                      requiredFields[ImporterDetailsEntity.ADDRESS.TOWN]
                    }
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={S.COUNTY_STATE}
                    name={ImporterDetailsEntity.ADDRESS.COUNTY}
                    maxLength={35}
                    helperText={S.MAX_35_CHARACTERS}
                    onBlur={onFieldEntry}
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              {(requiredFields[ImporterDetailsEntity.CONTACT_DETAILS.EMAIL] ||
                requiredFields[
                  ImporterDetailsEntity.CONTACT_DETAILS.TELEPHONE
                ]) && (
                <Row>
                  <Col>
                    <DeliveryContactCard />
                  </Col>
                </Row>
              )}
            </Col>
            <Col>
              <Row>
                <Col>
                  <Field
                    component={FormControl.Input}
                    label={S.CONTACT_NAME}
                    name={ImporterDetailsEntity.CONTACT_DETAILS.CONTACT_NAME}
                    maxLength={35}
                    helperText={S.MAX_35_CHARACTERS}
                    onBlur={onFieldEntry}
                    required={
                      requiredFields[
                        ImporterDetailsEntity.CONTACT_DETAILS.CONTACT_NAME
                      ]
                    }
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={DoubleAstericksInput}
                    label={S.CONTACT_TELEPHONE}
                    name={ImporterDetailsEntity.CONTACT_DETAILS.TELEPHONE}
                    helperText={S.MAX_15_CHARACTERS_INCLUDING_SPACES}
                    onBlur={onFieldEntry}
                    required={
                      requiredFields[
                        ImporterDetailsEntity.CONTACT_DETAILS.TELEPHONE
                      ]
                    }
                    normalize={flow(
                      Normalizers.emptyValueNormalize,
                      Normalizers.phoneNumberNormalize
                    )}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={DoubleAstericksInput}
                    label={S.NOTIFICATION_EMAIL}
                    name={ImporterDetailsEntity.CONTACT_DETAILS.EMAIL}
                    maxLength={100}
                    helperText={S.MAX_100_CHARACTERS}
                    onBlur={onFieldEntry}
                    required={
                      requiredFields[
                        ImporterDetailsEntity.CONTACT_DETAILS.EMAIL
                      ]
                    }
                    normalize={Normalizers.emptyValueNormalize}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              {isNewVersion ? (
                <NewImporterBusinessFields
                  requiredFields={requiredFields}
                  allowedFields={allowedFields}
                  onFieldEntry={onFieldEntry}
                />
              ) : (
                !hideOptions && (
                  <OldImporterBusinessFields
                    classes={{
                      importerType: styles.importerType,
                      typeRelatedField: styles.typeRelatedField,
                    }}
                    onImporterTypeChange={onImporterTypeChange}
                    isBusiness={getValue(
                      formValues,
                      ImporterDetailsEntity.IS_BUSINESS,
                      false
                    )}
                    requiredFields={requiredFields}
                    onFieldEntry={onFieldEntry}
                  />
                )
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={onCancel}>
          {S.CANCEL}
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {S.SAVE}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

EditImporterModal.propTypes = {
  ...propTypes,
  shippingVersion: PropTypes.number,
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  countries: PropTypes.array,
  requiredFields: PropTypes.object,
  allowedFields: PropTypes.object,
  editImporterSelectedCountry: PropTypes.object,
  values: PropTypes.object,
  selectedService: PropTypes.object,
  formValues: PropTypes.object,
  createShipmentValues: PropTypes.object,
  onHide: PropTypes.func,
  onReset: PropTypes.func,
  onFieldEntry: PropTypes.func,
  onPostcodeSelectionChange: PropTypes.func,
  onImporterTypeChange: PropTypes.func,
  onCountryChange: PropTypes.func,
};

export default compose(
  withInvoicePostcodeAutocomplete(EDIT_IMPORTER_FORM),
  connect(
    (
      state,
      { editImporterInitialValues, selectedService, createShipmentValues }
    ) => ({
      initialValues: editImporterInitialValues,
      requiredFields: getRequiredFields(selectedService)(state),
      allowedFields: getAllowedFields(createShipmentValues)(state),
      editImporterSelectedCountry: getImporterSelectedCountry(state),
      formValues: getEditImporterFormValues(state),
      customer: UmsSelectors.getCustomer(state),
    }),
    (dispatch, { selectedAddressBook }) => ({
      onReset: () => dispatch(reset(EDIT_IMPORTER_FORM)),
      onCountryChange: selection =>
        dispatch(
          ShipmentActions.changeCountry(
            { formName: EDIT_IMPORTER_FORM },
            ImporterDetailsEntity.ADDRESS.COUNTRY_CODE,
            selection.value
          )
        ),
    })
  ),
  withHandlers({
    onSubmit:
      ({ saveInvoiceImporterDetails }) =>
      values =>
        saveInvoiceImporterDetails(values),
    onSubmitSuccess:
      ({ onHide, onReset }) =>
      () => {
        onHide();
        onReset();
      },
  }),
  reduxForm({
    form: EDIT_IMPORTER_FORM,
    shouldError: () => true,
    validate: (values, props) =>
      createValidator(importerDetailsSchema(props), [
        () =>
          postcodeValidation(
            props,
            props.editImporterSelectedCountry,
            ImporterDetailsEntity.ADDRESS.POSTCODE
          ),
        () =>
          invoiceImporterCountryValidation(
            props,
            ImporterDetailsEntity.ADDRESS.COUNTRY_CODE
          ),
        () => importerUkimsValidation(props),
        () => importerEoriValidation(props),
      ])(values, props),
    enableReinitialize: true,
  }),
  lifecycle({
    componentDidMount() {
      const { dispatch, editImporterInitialValues } = this.props;
      dispatch(touchFields(EDIT_IMPORTER_FORM, editImporterInitialValues));
    },
  })
)(EditImporterModal);
